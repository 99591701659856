import React from "react";
import Card from "antd/es/card";
import PropTypes from "prop-types";


const Widget = ({ title, children, styleName, cover, actions, backgroundColor, flex, style}) => {

  return (
    <Card 
     actions={actions} 
      cover={cover} 
      style={{ 
        ...style,
        flex : flex,  
        boxShadow : "0 2px 2px 2px  rgba(0, 0, 0, 0.2)", 
        backgroundColor : `${backgroundColor}`
      }} 
      className={`gx-card-widget ${styleName}`} 
    >
      {title &&  <h2>{title}</h2>}
      {children}
    </Card>
  )
};

export default Widget;

Widget.defaultProps = {
  styleName: '',
};

Widget.propTypes = {
  title: PropTypes.node,
  extra: PropTypes.node,
  cover: PropTypes.node,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired
};
