import React, { Component } from "react";
import Menu from "antd/es/menu";
import Icon from "antd/es/icon";
import { NavLink } from "react-router-dom";
import axios from "axios";

import "../../styles/layout/sidebar.css";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import {
  TAB_SIZE,
  NAV_STYLE,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import UserProfile from "./UserProfile";
import TierInfo from "./TierInfo";
import { getWhiteLabelSupportMeetingLink } from "util/whitelabel";
import { getSubscribedAddonsLegacyContract } from "util/functions/getSubscribedAddons";

const { SubMenu } = Menu;

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addons: null,
      plan_id: null,
      user_type: null,
      navStyle: null,
      addonDataLoaded: false,
      awsMargin: "0px 0px 0px 5px",
      defaultOpenKeys: ["compliance"],
      pentestMargin: "0px 0px 0px -52px",
      awsClass: "",
      pentestClass: "",
      lockedAwsClass: "",
      lockedAwsMargin: "",
      lockedPentestClass: "",
      lockedPentestMargin: "",
      openKeys: []
    };
  }

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  handleLinkColor = (policy, policyId) => {
    if (policy === "complianceManagement") {
      if (
        (this.state.addons && this.state.addons.includes("soc2")) ||
        (this.state.addons && this.state.addons.includes("iso27001")) ||
        (this.state.addons && this.state.addons.includes("soc1"))
      ) {
        return (
          <span className="submenu-unlocked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      } else {
        return (
          <span className="locked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      }
    } else {
      if (this.state.addons && this.state.addons.includes(policy)) {
        return (
          <span className="submenu-unlocked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      } else {
        return (
          <span className="locked-link-color">
            <IntlMessages id={policyId} />
          </span>
        );
      }
    }
  };

  handleLinkColorWithImage = (policyId, icon) => {
    return (
      <span className="flex-align-center-sidebar">
        <Icon type={icon} style={{ fontSize: "20px", color: "DodgerBlue" }} />
        <span
          className="unlocked-link-color"
          style={{ color: this.handleUnlockedLinkColor() }}
        >
          <IntlMessages id={policyId} />
        </span>
      </span>
    );
  };

  handleChangingLinkColorWithImage = (
    policy,
    policyId,
    greyImage,
    blueImage,
    alt
  ) => {
    if (this.state.addons && !this.state.addons.includes(policy)) {
      return (
        <span className="flex-align-center-sidebar locked-link-color">
          <Icon
            component={() => (
              <img
                alt={alt}
                style={{
                  maxWidth: "25px",
                  height: "25px",
                  margin: "0 -2px 0 -3px"
                }}
                src={greyImage}
              />
            )}
          />
          <IntlMessages id={policyId} />
        </span>
      );
    } else {
      return (
        <span className="flex-align-center-sidebar">
          <Icon
            component={() => (
              <img
                alt={alt}
                style={{
                  maxWidth: "25px",
                  height: "25px",
                  margin: "0 -2px 0 -3px"
                }}
                src={blueImage}
              />
            )}
          />
          <span
            className="unlocked-link-color"
            style={{ color: this.handleUnlockedLinkColor() }}
          >
            <IntlMessages id={policyId} />
          </span>
        </span>
      );
    }
  };

  OpenKeysCheck = data => {
    let openKeys = [];

    if (data === "NAV_STYLE_MINI_SIDEBAR") {
      this.setState({ openKeys: [] });
    } else {
      if (
        window.location.pathname.includes(
          "compliance/compliancemanagement/devicemanagement"
        )
      ) {
        openKeys.push("compliance");
        openKeys.push("complianceManagement");
        openKeys.push("deviceManagementSubMenu");
      } else if (
        window.location.pathname.includes("compliance/compliancemanagement")
      ) {
        openKeys.push("compliance");
        openKeys.push("complianceManagement");
      } else if (
        window.location.pathname.includes("compliance") ||
        window.location.pathname.includes("portal")
      ) {
        openKeys.push("compliance");
      }

      if (window.location.pathname.includes("account/usersettings")) {
        openKeys.push("account");
        openKeys.push("account/usersettings");
      } else if (window.location.pathname.includes("account")) {
        openKeys.push("account");
      }
      if (window.location.pathname.includes("soc1")) {
        openKeys.push("soc1");
      }

      this.setState({ openKeys });
    }
  };

  async componentDidMount() {
    this.OpenKeysCheck(this.props.navStyle);
    let addons = null;
    let plan_id = null;
    await getSubscribedAddonsLegacyContract().then(res => {
      if (res) {
        if (typeof res.data === "string") {
          addons = res.data.split(",");

          if (addons && typeof addons !== "undefined" && addons.length > 0) {
            addons.forEach((addon, index) => {
              if (addon === "cis") {
                addons[index] = "cislevel1";
                addons = addons.concat("cislevel2");
              }
            });
          }
        }
        this.setState({
          navStyle: this.props.navStyle,
          addons: addons,
          addonDataLoaded: true
        });
      }
    });

    await axios.get("/backend/constant/getPlanId").then(res => {
      plan_id = res.data;
      this.setState({ plan_id: plan_id });
      if (plan_id === "free") {
        this.setState({ addons: "cislevel1,cislevel2" });
      }
    });

    await axios.get("/backend/constant/getUserType").then(res => {
      var user_type = res.data;
      this.setState({ user_type: user_type });
    });
    try {
      if (!addons.includes("awswellarch")) {
        this.setState({ lockedAwsClass: "locked-icon-sidebar-text" });
      }
      if (!addons.includes("pentest")) {
        this.setState({ lockedPentestClass: "locked-icon-sidebar-text" });
      }
      if (plan_id.includes("free")) {
        this.setState({ lockedQuestionnaireClass: "locked-icon-sidebar-text" });
      }
    } catch {
      this.setState({
        lockedAwsClass: "locked-icon-sidebar-text",
        lockedPentestClass: "locked-icon-sidebar-text",
        lockedQuestionnaireClass: "locked-icon-sidebar-text",
        firstRender: false
      });
    }
  }
  handleUnlockedLinkColor = () => {
    if (this.props.navOpen === false && this.props.windowWidth > TAB_SIZE) {
      return "white";
    } else {
      return "#232323";
    }
  };
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ navStyle: newProps.navStyle });
    if (newProps.navStyle !== this.props.navStyle) {
      this.OpenKeysCheck(newProps.navStyle);
    }
  }
  onOpenChange = value => {
    this.setState({ openKeys: value });
  };
  render() {
    const { themeType, pathname, navStyle, cloudType } = this.props;
    const {
      addonDataLoaded,
      addons,
      openKeys,
      plan_id,
      user_type
    } = this.state;

    const selectedKeys = pathname.substr(1);
    return (
      <Auxiliary>
        <SidebarLogo windowWidth={this.props.windowWidth} />
        <div className="gx-sidebar-content">
          <div className="sidebar">
            {addonDataLoaded === false ? null : (
              <div style={{ overflow: "auto", overflowX: "hidden" }}>
                <div
                  className={`gx-sidebar-notifications ${this.getNoHeaderClass(
                    navStyle
                  )}`}
                >
                  <UserProfile />
                  <TierInfo navStyle={navStyle} />
                </div>
                <div className="gx-layout-sider-scrollbar">
                  <Menu
                    onOpenChange={this.onOpenChange}
                    triggerSubMenuAction={
                      window.innerWidth > TAB_SIZE &&
                        navStyle === "NAV_STYLE_MINI_SIDEBAR"
                        ? "hover"
                        : "click"
                    }
                    openKeys={openKeys}
                    defaultSelectedKeys={[selectedKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                    mode={"inline"}
                  >
                    <Menu.Item key="portal/dashboard">
                      <NavLink
                        to="/portal/dashboard"
                        activestyle={{
                          borderBottom: "solid 3px #fff",
                          paddingBottom: "1em"
                        }}
                      >
                        <span className="flex-align-center-sidebar">
                          <Icon
                            type="bar-chart"
                            style={{ fontSize: "20px", color: "DodgerBlue" }}
                          />
                          <span
                            className="unlocked-link-color"
                            style={{ color: this.handleUnlockedLinkColor() }}
                          >
                            <IntlMessages id="sidebar.summary.overall.summary" />
                          </span>
                        </span>
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="compliance"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      activestyle={{
                        color: "orange"
                      }}
                      title={
                        <span>
                          {!addons[0] ? (
                            <span>
                              <Icon
                                type="safety"
                                style={{
                                  fontSize: "20px",
                                  color: "rgb(202,202,202)",
                                  marginTop: ".5em",
                                  marginRight: "1em"
                                }}
                              />
                              <span className="locked-link-color">
                                <IntlMessages id="sidebar.compliance" />
                              </span>
                            </span>
                          ) : (
                            <span>
                              <Icon
                                type="safety"
                                style={{
                                  fontSize: "20px",
                                  color: "DodgerBlue",
                                  marginTop: ".5em",
                                  marginRight: "1em"
                                }}
                              />{" "}
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.compliance" />
                              </span>
                            </span>
                          )}
                        </span>
                      }
                    >
                      <Menu.Item key="portal/compliance/summary">
                        <NavLink
                          to="/portal/compliance/summary"
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          className="padding-left-sidebar"
                        >
                          {!addons[0] ? (
                            <span className="locked-link-color">
                              <IntlMessages id="sidebar.compliance.summary" />
                            </span>
                          ) : (
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.compliance.summary" />
                            </span>
                          )}
                        </NavLink>
                      </Menu.Item>

                      { cloudType === "aws" ? (
                        <Menu.Item key="portal/compliance/cislevel1">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/compliance/cislevel1"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "cislevel1",
                              "sidebar.compliance.cislevel1"
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null }

                      { cloudType === "aws" ? (
                        <Menu.Item key="portal/compliance/cislevel2">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/compliance/cislevel2"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "cislevel2",
                              "sidebar.compliance.cislevel2"
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null }

                      <Menu.Item key="portal/compliance/soc1">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/soc1"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColor(
                            "soc1",
                            "sidebar.portal.compliance.soc1"
                          )}
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="portal/compliance/soc2">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/soc2"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColor(
                            "soc2",
                            "sidebar.portal.compliance.soc2"
                          )}
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="portal/compliance/hipaa">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/hipaa"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColor(
                            "hipaa",
                            "sidebar.compliance.hipaa"
                          )}
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="portal/compliance/iso27001">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/iso27001"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColor(
                            "iso27001",
                            "sidebar.compliance.iso27001"
                          )}
                        </NavLink>
                      </Menu.Item>
                      
                      {cloudType === "aws" ? (
                        <Menu.Item key="portal/compliance/nist171">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/compliance/nist171"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "nist171",
                              "sidebar.compliance.nist171"
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null}

                      {cloudType === "aws" ? (                    
                        <Menu.Item key="portal/compliance/nist853">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/compliance/nist853"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "nist853",
                              "sidebar.compliance.nist853"
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null}

                      <SubMenu
                        key="complianceManagement"
                        className={
                          (this.getNavStyleSubMenuClass(navStyle),
                            "padding-left-sidebar-menu")
                        }
                        activestyle={{
                          color: "orange"
                        }}
                        title={
                          (addons && addons.includes("soc2")) ||
                            (addons && addons.includes("iso27001")) ||
                            (addons && addons.includes("soc1")) ? (
                            <span>
                              <span
                                style={{
                                  marginLeft: "10px",
                                  position: "relative",
                                  bottom: "2px",
                                  right: "13px"
                                }}
                              >
                                {" "}
                                <span className="submenu-unlocked-link-color">
                                  <IntlMessages id="sidebar.portal.compliance.complianceManagement" />{" "}
                                </span>
                              </span>
                            </span>
                          ) : (
                            <span className="locked-link-color">
                              <span
                                style={{
                                  marginLeft: "10px",
                                  position: "relative",
                                  bottom: "2px",
                                  right: "13px"
                                }}
                              >
                                {" "}
                                <IntlMessages id="sidebar.portal.compliance.complianceManagement" />{" "}
                              </span>
                            </span>
                          )
                        }
                      >
                        <Menu.Item key="portal/compliance/compliancemanagement/personnel">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={
                              "/portal/compliance/compliancemanagement/personnel"
                            }
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "complianceManagement",
                              "sidebar.portal.compliance.complianceManagement.personnel"
                            )}
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item key="portal/compliance/compliancemanagement/roles">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={"/portal/compliance/compliancemanagement/roles"}
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "complianceManagement",
                              "sidebar.portal.compliance.complianceManagement.roles"
                            )}
                          </NavLink>
                        </Menu.Item>
                        <Menu.Item key="portal/compliance/compliancemanagement/vendors">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={
                              "/portal/compliance/compliancemanagement/vendors"
                            }
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "complianceManagement",
                              "sidebar.portal.compliance.complianceManagement.vendors"
                            )}
                          </NavLink>
                        </Menu.Item>

                        <Menu.Item key="portal/compliance/compliancemanagement/questionnaire">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to={
                              "/portal/compliance/compliancemanagement/questionnaire"
                            }
                            className="padding-left-sidebar"
                          >
                            {this.handleLinkColor(
                              "complianceManagement",
                              "sidebar.portal.compliance.complianceManagement.questionnaire"
                            )}
                          </NavLink>
                        </Menu.Item>
                        <SubMenu
                          id="deviceManagementSubMenu"
                          key="deviceManagementSubMenu"
                          activestyle={{
                            color: "orange"
                          }}
                          title={
                            (addons && addons.includes("soc2")) ||
                              (addons && addons.includes("iso27001")) ||
                              (addons && addons.includes("soc1")) ? (
                              <span>
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    position: "relative",
                                    bottom: "2px",
                                    right: "13px"
                                  }}
                                >
                                  {" "}
                                  <span className="submenu-unlocked-link-color">
                                    <IntlMessages id="sidebar.portal.compliance.complianceManagement.deviceManagement" />{" "}
                                  </span>
                                </span>
                              </span>
                            ) : (
                              <span className="locked-link-color">
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    position: "relative",
                                    bottom: "2px",
                                    right: "13px"
                                  }}
                                >
                                  {" "}
                                  <IntlMessages id="sidebar.portal.compliance.complianceManagement.deviceManagement" />{" "}
                                </span>
                              </span>
                            )
                          }
                        >
                          <Menu.Item key="portal/compliance/compliancemanagement/devicemanagement/deviceauthorization">
                            <NavLink
                              activeStyle={{
                                borderBottom: "solid 3px #fff",
                                paddingBottom: "1em"
                              }}
                              to={
                                "/portal/compliance/compliancemanagement/devicemanagement/deviceauthorization"
                              }
                              className="padding-left-sidebar"
                            >
                              {this.handleLinkColor(
                                "complianceManagement",
                                "sidebar.portal.compliance.complianceManagement.deviceManagement.deviceAuthorization"
                              )}
                            </NavLink>
                          </Menu.Item>
                          <Menu.Item key="portal/compliance/compliancemanagement/devicemanagement/devices">
                            <NavLink
                              activeStyle={{
                                borderBottom: "solid 3px #fff",
                                paddingBottom: "1em"
                              }}
                              to={
                                "/portal/compliance/compliancemanagement/devicemanagement/devices"
                              }
                              className="padding-left-sidebar"
                            >
                              {this.handleLinkColor(
                                "complianceManagement",
                                "sidebar.portal.compliance.complianceManagement.deviceManagement.devices"
                              )}
                            </NavLink>
                          </Menu.Item>
                        </SubMenu>
                      </SubMenu>
                    </SubMenu>
                    {/* 
                      If we add Azure Well Arch in the future, we should probably call this Well Architected instead
                      For now, hiding the nav link, and not allowing azure cloud accounts to go to well arch
                    */}
                    {cloudType === "aws" ? (
                      <Menu.Item key="portal/compliance/awswellarch">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/compliance/awswellarch"}
                          className="padding-left-sidebar"
                        >
                          {this.handleChangingLinkColorWithImage(
                            "awswellarch",
                            "sidebar.compliance.awswellarch",

                            require("assets/images/AWS_WA_ICON_2_GREY.png")
                              .default,
                            require("assets/images/AWS_WA_ICON_2_BLUE.png")
                              .default,
                            "AWS Well Arch"
                          )}
                        </NavLink>
                      </Menu.Item>
                    ) : null}
                    
                    <Menu.Item key="portal/compliance/security">
                      <NavLink
                        activeStyle={{
                          borderBottom: "solid 3px #fff",
                          paddingBottom: "1em"
                        }}
                        to={"/portal/compliance/security"}
                        className="padding-left-sidebar"
                      >
                        {this.handleLinkColorWithImage(
                          "sidebar.security",
                          "safety-certificate"
                        )}
                      </NavLink>
                    </Menu.Item>
                    
                    <Menu.Item key="portal/compliance/assetinventory">
                      <NavLink
                        activeStyle={{
                          borderBottom: "solid 3px #fff",
                          paddingBottom: "1em"
                        }}
                        to={"/portal/compliance/assetinventory"}
                        className="padding-left-sidebar"
                      >
                        {this.handleLinkColorWithImage(
                          "sidebar.cloudresources",
                          "cloud"
                        )}
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="portal/pentest/dashboard">
                      <NavLink
                        activeStyle={{
                          borderBottom: "solid 3px #fff",
                          paddingBottom: "1em"
                        }}
                        to={"/portal/pentest/dashboard"}
                        className="padding-left-sidebar"
                      >
                        {this.handleChangingLinkColorWithImage(
                          "pentest",
                          "sidebar.pentest",

                          require("assets/images/pen/pen2_grey.png").default,
                          require("assets/images/pen/pen2_blue.png").default,
                          "WebApp Scanner"
                        )}
                      </NavLink>
                    </Menu.Item>
                    {cloudType === "aws" ? (
                      <Menu.Item key="portal/costsavings">
                        <NavLink
                          activeStyle={{
                            borderBottom: "solid 3px #fff",
                            paddingBottom: "1em"
                          }}
                          to={"/portal/costsavings"}
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColorWithImage(
                            "sidebar.costsavings",
                            "dollar"
                          )}
                        </NavLink>
                      </Menu.Item>
                    ) : null}
                    <SubMenu
                      key="account"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      activestyle={{
                        color: "orange"
                      }}
                      title={
                        <span className=" align-middle">
                          <Icon
                            type="solution"
                            style={{
                              fontSize: "20px",
                              color: "DodgerBlue",
                              marginTop: ".5em",
                              marginRight: "1em"
                            }}
                          />{" "}
                          <span className="submenu-unlocked-link-color">
                            <IntlMessages
                              className="align-middle"
                              id="sidebar.account"
                            />
                          </span>
                        </span>
                      }
                    >
                      <SubMenu
                        key="account/usersettings"
                        className={
                          (this.getNavStyleSubMenuClass(navStyle),
                            "padding-left-sidebar-menu")
                        }
                        activestyle={{
                          color: "orange"
                        }}
                        title={
                          <span className=" align-middle">
                            <Icon
                              type="tool"
                              style={{
                                fontSize: "20px",
                                color: "DodgerBlue",
                                marginTop: ".5em",
                                marginRight: "0.3em"
                              }}
                            />{" "}
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages
                                className="align-left"
                                id="sidebar.account.usersettings"
                              />
                            </span>
                          </span>
                        }
                      >
                        <Menu.Item key="account/usersettings/profile">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to="/account/usersettings/profile"
                          >
                            <span className="submenu-unlocked-link-color">
                              Profile
                            </span>
                          </NavLink>
                        </Menu.Item>
                        {user_type !== null &&
                          typeof user_type !== "undefined" &&
                          user_type !== "subuser" ? (
                          <Menu.Item key="account/usersettings/notifications">
                            <NavLink
                              activeStyle={{
                                borderBottom: "solid 3px #fff",
                                paddingBottom: "1em"
                              }}
                              to="/account/usersettings/notifications"
                            >
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.account.notifications" />
                              </span>
                            </NavLink>
                          </Menu.Item>
                        ) : null}
                      </SubMenu>
                      {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item key="account/plandetails">
                          <NavLink
                            activeStyle={{
                              color: "orange"
                            }}
                            to="/account/plandetails"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.plandetails" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                      ) : null}
                      {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item
                          key="account/subusers"
                          disabled={
                            user_type !== null &&
                            typeof user_type !== "undefined" &&
                            user_type === "subuser"
                          }
                        >
                          <NavLink
                            activeStyle={{
                              color: "orange"
                            }}
                            to="/account/subusers"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.subusers" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                      ) : null}
                      {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item key="account/addons">
                          <NavLink
                            activeStyle={{
                              borderBottom: "solid 3px #fff",
                              paddingBottom: "1em"
                            }}
                            to="/account/addons"
                            className="padding-left-sidebar"
                          >
                            <span className="submenu-unlocked-link-color">
                              <IntlMessages id="sidebar.account.addons" />
                            </span>
                          </NavLink>
                        </Menu.Item>
                      ) : null}

                      <Menu.Item key="account/cloudaccounts">
                        <NavLink
                          activestyle={{
                            color: "orange"
                          }}
                          to="/account/cloudaccounts"
                          className="padding-left-sidebar"
                        >
                          <span className="submenu-unlocked-link-color">
                            <IntlMessages id="sidebar.account.cloudaccounts" />
                          </span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="account/auditormanagement">
                        <NavLink
                          activestyle={{
                            color: "orange"
                          }}
                          to="/account/auditormanagement"
                          className="padding-left-sidebar"
                        >
                          {this.handleLinkColor(
                            "soc1",
                            "sidebar.account.auditormanagement"
                          )}
                        </NavLink>
                      </Menu.Item>
                      {user_type !== null &&
                        typeof user_type !== "undefined" &&
                        user_type !== "subuser" ? (
                        <Menu.Item key="account/invoices">
                          <NavLink
                            to="/account/invoices"
                            className="padding-left-sidebar"
                          >
                            {plan_id && plan_id !== "free" ? (
                              <span className="submenu-unlocked-link-color">
                                <IntlMessages id="sidebar.account.invoices" />
                              </span>
                            ) : (
                              <span className="locked-link-color">
                                <IntlMessages id="sidebar.account.invoices" />
                              </span>
                            )}
                          </NavLink>
                        </Menu.Item>
                      ) : null}
                      <Menu.Item key="icompaas-support">
                        <a
                          href={getWhiteLabelSupportMeetingLink()}
                          target="_blank"
                          className="padding-left-sidebar"
                        >
                          <span className="submenu-unlocked-link-color">
                            <IntlMessages id="sidebar.account.support" />
                            &nbsp;&nbsp;
                          </span>
                          <i
                            className="fas fa-external-link-alt"
                            style={{ color: "dodgerblue" }}
                          ></i>
                        </a>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({ settings, PlanId, PlanDetails, cloudType }) => {
  const { navStyle, themeType, locale, pathname, navOpen } = settings;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return { navStyle, themeType, locale, pathname, name, details, navOpen, cloudType };
};

const mapDispatchToProps = dispatch => {
  return {
    PlanId: name => dispatch({ type: "PlanId", payload: name }),
    PlanDetails: details => dispatch({ type: "PlanDetails", payload: details })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
