import React from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Icon from "antd/es/icon";
import Input from "antd/es/input";
import { printMsg } from "../util/functions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SET_CLOUD_ACCOUNT_ID,
  SET_CLOUD_TYPE
} from "constants/ActionTypes";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import Background from "assets/images/mission2.png";
import { getWhiteLabelPartnerName, getWhiteLabelMainWebsiteUrlBase, getWhiteLabelLogoUrl, getIsWhiteLabelAppPartner } from "util/whitelabel";

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  toggleModal = event => {
    const { isOpen } = this.state;
    this.setState(
      { isOpen: !isOpen }
    );
    if (this.state.isOpen === true) {
      this.props.neededTrue();
    } else {
      this.props.neededFalse();
    }
  }

  toggleReadOnly = () => {
    this.setState(prevState => ({ readOnly: !prevState.readOnly }));
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (this.props.location.pathname === "/partnersignin") {
        values.partner = true;
      }

      if (!err) {
        try {
          this.props.userSignIn(values);
        } catch (error) {
        }

        this.props.showAuthLoader();
      }
    });
  }
  
  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  async componentDidMount() {
    // if user is redirected from auditor signin, show a message
    const urlParams = new URLSearchParams(this.props.location.search);
    const from = urlParams.get("from");
    if (from && from === "auditor") {
      window.history.pushState({}, document.title, "/signin");
      printMsg("info", "The Auditor Sign In page has been moved here.", 10)
    }

    this.props.planIdClear();
    this.props.planDetailsClear();
    this.props.cloudAccountIdClear();
    sessionStorage.removeItem("cloudAccountId");
    this.props.cloudTypeClear();
    sessionStorage.removeItem("cloudType");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "right",

          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="gx-app-login-container ">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div
                className="gx-app-logo-content-bg"
                style={{ backgroundColor: "##3099cabd" }}
              ></div>
              <div className="gx-app-logo-wid">
                {this.props.location.pathname === "/partnersignin" ? (
                  <h1> Partner Sign In</h1>
                ) : (
                  <h1>
                    <IntlMessages id="app.userAuth.signIn" />
                  </h1>
                )}

                <p>Cloud Infrastructure Security and Compliance Starts Here</p>
              </div>
              <div
                className="gx-app-logo "
                style={{ display: "inline-block", textAlign: "center" }}
              >
                <img
                  alt={getWhiteLabelPartnerName()}
                  src={getWhiteLabelLogoUrl() === "https://images.icompaas.com/i/ic-LogoNtext-Main.png" ? require("assets/images/white-logo.png").default : getWhiteLabelLogoUrl()}
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem className="gx-py-3" style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Please enter the email"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Email"
                      prefix={
                        <Icon type="user" style={{ color: "DodgerBlue" }} />
                      }
                      size="large"                        
                      disabled={loader}
                    />
                  )}
                </FormItem>
                <FormItem className="gx-py-3">
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please enter the password" }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon type="lock" style={{ color: "DodgerBlue" }} />
                      }
                      type="password"
                      placeholder="Password"                      
                      size="large"
                      disabled={loader}
                    />
                  )}
                </FormItem>

                <Form.Item>
                  <div className="gx-col-flex">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loader}
                      block
                    >
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>                    
                    <div className="gx-row-flex">
                      {getIsWhiteLabelAppPartner() === false ? (
                        <div style={{ alignContent: "left" }}>
                          <a
                            href={`${getWhiteLabelMainWebsiteUrlBase()}/signup/account/`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <IntlMessages id="app.userAuth.signUp" />
                          </a>
                        </div>
                      ) : null}

                      {getIsWhiteLabelAppPartner() === false ? (                      
                        <div style={{ alignContent: "left" }}>
                          <a href="/auditor/signup">
                            <IntlMessages id="app.userAuth.auditorSignUp" />
                          </a>
                        </div>
                      ) : null}

                      <div style={{ alignContent: "right" }}>
                        <Link
                          className="gx-login-form-forgot"
                          to="/forgotpassword"
                        >
                          {" "}
                          <IntlMessages id="app.userAuth.forgotPassword" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>

            {showMessage ? printMsg("error", alertMessage.toString(), 5) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth, CloudAccount, PlanId, PlanDetails }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { needed } = CloudAccount;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return { loader, alertMessage, showMessage, authUser, needed, name, details };
};

const mapDispatchToProps = dispatch => {
  return {
    neededTrue: () => dispatch({ type: "neededTrue" }),
    neededFalse: () => dispatch({ type: "neededFalse" }),
    planIdClear: () => dispatch({ type: "PlanIdClear" }),
    planDetailsClear: () => dispatch({ type: "PlanDetailsClear" }),
    cloudAccountIdClear: data => dispatch({ type: SET_CLOUD_ACCOUNT_ID, payload: null }),
    cloudTypeClear: data => dispatch({ type: SET_CLOUD_TYPE, payload: null }),
    userSignIn: user => dispatch({ type: SIGNIN_USER, payload: user }),
    hideMessage: () => dispatch({ type: HIDE_MESSAGE }),
    showAuthLoader: () => dispatch({ type: ON_SHOW_LOADER }),
    userFacebookSignIn: () => dispatch({ type: SIGNIN_FACEBOOK_USER }),
    userGoogleSignIn: () => dispatch({ type: SIGNIN_GOOGLE_USER }),
    userGithubSignIn: () => dispatch({ type: SIGNIN_GITHUB_USER }),
    userTwitterSignIn: () => dispatch({ type: SIGNIN_TWITTER_USER })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);
