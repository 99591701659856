import React, { Component } from "react";
import { connect } from "react-redux";
import { tierMap } from "util/Constants";
import checkPlanId from "../../appRedux/actions/PlanId";

class TierInfo extends Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      plan_id: null,
      tierDataLoaded: false
    };

    this.hide = this.hide.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  hide = () => {
    this.setState({
      visible: false
    });
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  async componentDidMount() {
    let plan_id = await checkPlanId(this.props.name);
    this.setState({ plan_id: plan_id });
    this.setState({ tierDataLoaded: true });
    if (this.props.name === "") {
      this.props.PlanId(plan_id);
    }
  }

  render() {
    const { plan_id, tierDataLoaded } = this.state;
    const { navStyle } = this.props;

    if (navStyle === "NAV_STYLE_MINI_SIDEBAR")
      return (
        <div
          className="gx-flex-row gx-align-items-left gx-my-2"
          style={{ margin: "-10px" }}
        >
          {tierDataLoaded === false ? null : (
            <span
              className="text-uppercase gx-align-items-center"
              style={{
                color: "DodgerBlue",
                fontSize: "12px",
                borderRadius: "10px",
                padding: "4px",
                border: "2px solid"
              }}
            >
              {tierMap[plan_id] + "     Tier "}
            </span>
          )}
        </div>
      );
    else
      return (
        <div className="gx-flex-row gx-align-items-right gx-mx-3 ">
          {tierDataLoaded === false ? null : (
            <span
              className="text-uppercase "
              style={{
                color: "DodgerBlue",
                fontSize: "15px",
                borderRadius: "15px",
                padding: "10px",
                border: "2px solid"
              }}
            >
              {tierMap[plan_id] + "     Tier "}
            </span>
          )}
        </div>
      );
  }
}

const mapStateToProps = ({ PlanId }) => {
  const { name } = PlanId;
  return { name };
}

const mapDispatchToProps = dispatch => {
  return {
    PlanId: name => dispatch({ type: "PlanId", payload: name })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TierInfo);
