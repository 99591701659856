import React, { Component } from "react";
import Popover from "antd/es/popover";
import { signout } from "util/functions";
import axios from "axios";
import { Link } from "react-router-dom";

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      visible : false,
      first_name : "",
      last_name : ""
    }

    this.hide = this.hide.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
   }

  hide = () => {
    this.setState({
      visible: false,
    });
  }
  
  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  componentDidMount(){
    axios
    .get("/backend/account/userSettings" )
    .then(res => {
      this.setState (res.data);
    })
    .catch(error => {
      this.setState({
        errorMsg: `Error retreiving User's details. Detailed error : ${error}`
      })
    }) ;
  }

  render() {
    const {first_name , last_name} = this.state;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li > <Link onClick = {() => this.hide()} to="/account/usersettings/profile">User Settings</Link> </li>
        <li onClick={() => signout()}>Logout</li>
      </ul>
    );
   
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click" visible={this.state.visible}
               onVisibleChange={this.handleVisibleChange}>
                 <div className="gx-flex-row gx-align-items-center gx-avatar-row">
        <i className="fas fa-user-circle gx-mr-2" style={{ fontSize :'20px' , color: 'DodgerBlue'}} />
         {" "}
          <span className="gx-avatar-name ">{first_name + " "+last_name}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span></div>
        </Popover>
      </div>
    )
  }
}

export default UserProfile;
