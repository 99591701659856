import React, { Component } from "react";
import Layout from "antd/es/layout";
import CloudAccountSelector from "../CloudAccountSelector";
import TimeoutWarning from "../../util/TimeoutWarning";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage,toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import "styles/pages/topbar.css";

const { Header } = Layout;

class Topbar extends Component {
  state = {
    searchText: "",
    windowWidth : window.innerWidth
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  )

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.setState({windowWidth : window.innerWidth}));
  }
   
  componentDidMount() {
 
   window.addEventListener('resize', 
   () => this.setState({windowWidth : window.innerWidth})
    );
  }

  render() {
    const {   navCollapsed, navStyle } = this.props;
    const {windowWidth} = this.state;

    return (
      <Auxiliary>
        <Header className="no-wrap">
          {navStyle === NAV_STYLE_DRAWER ||
          ((navStyle === NAV_STYLE_FIXED ||
            navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            windowWidth < TAB_SIZE) ? (
            <div className="gx-linebar d-flex justify-content-center align-items-center" >
              <i 
                className="gx-icon-btn icon icon-menu "
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div>
          ) : null}
          {window.innerWidth < "400"?
            <CloudAccountSelector width="280px" style={{ display : "inline-block"}}/>
            :
            <CloudAccountSelector width="85%" style={{ display : "inline-block"}}/>
          }  
          <ul style={{ display : "inline-block"}}className="gx-header-notifications gx-ml-auto "> 
            {windowWidth >= TAB_SIZE ? null : (
              <Auxiliary>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </Auxiliary>
            )}
          </ul>
        </Header>
        <TimeoutWarning />
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed } = settings;
  return { locale, navStyle, navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(Topbar);
